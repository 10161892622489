var resizeTimerObjects;

$(function () { 
    initObjects();
    initObjectDetail(); 
});

$(window).resize(function () {
    clearTimeout($.data(resizeTimerObjects, 'resizeTimerObjects'));
    $.data(this, 'resizeTimerObjects', setTimeout(function () {  
        initObjects(); 
        initObjectDetail(); 
    }, 275));
});

 
var initObjects = function(){
    if ($('#objects').length > 0) { 
        $('#objects .object a .info').height('auto');
        if($( window ).width() > 768) { 
            var highestBox = 0;
                $('#objects .object a .info').each(function(){  
                        if($(this).height() > highestBox){  
                        highestBox = $(this).height();  
                }
            });     
            $('#objects .object a .info').height(highestBox);
        }
    }
}

var initObjectDetail = function(){
    if ($('#object').length > 0) {
        initObjectTabs();
        initObjectDetailSlider(); 
    }
}

var initObjectTabs = function(){ 
    if ($('#leesmeer').length) {
        $('#leesmeer').click(function(e) {
            e.preventDefault();

            $('#tabs li').each(function() {
                if ($(this).find('a').attr('href') == '#Omschrijving') {
                    $('#tabs li.active').removeClass('active');
                    $(this).addClass('active');

                    $('[class^=object-].show').removeClass('show');
                    $('[data-tab=' + $(this).find('a').attr('href').replace('#', '') + ']').addClass('show');

                    $('html, body').animate({
                        scrollTop: $('#object-header').outerHeight()
                    }, 500);
                }
            });
        });
    }
    $('[data-tab]').each(function(){
        var tab     = $(this);
        var li      = $('<li><a href="#'+ tab.attr('data-tab') +'">'+ tab.attr('data-tab') +'</a>').appendTo($('#tabs'));
        if(tab.hasClass('show')) li.addClass('active');
        li.find('a').click(function(event){
            event.preventDefault();
            $('#tabs li').removeClass('active');
            li.addClass('active');
            $('[data-tab]').removeClass('show');
            tab.addClass('show'); 
            initActivateTab(tab.attr('data-tab')); 
        });
        var selectoption  = $('<option value="'+ tab.attr('data-tab') +'" class="option"><a href="#'+ tab.attr('id') +'">'+ tab.attr('data-tab') +'</a></option>').appendTo($('#tabs-select'));
        if(tab.hasClass('show')) selectoption.attr('selected','selected');
        
    });    
    // Selectbox for mobile
    $( "#tabs-select" ).change(function () {
        $('[data-tab]').removeClass('show');
        $( "select option:selected" ).each(function() {
            $('#object-content').find('[data-tab="' + $( this ).text() + '"]').addClass('show');
            initActivateTab($( this ).text()); 
        }); 
    }).change();

}

var initActivateTab = function(selectvalue){ 
    if(selectvalue == 'Algemeen')      initObjectDetailSlider();
    if(selectvalue == 'Kenmerken')     initObjectKenmerken();
    if(selectvalue == "Foto's")        initObjectFotos();
    if(selectvalue == 'Plattegrond')   initObjectPlattegronden();
    if(selectvalue == 'Locatie')       initObjectMaps();
    if(selectvalue == 'Streetview')    initObjectStreetview(); 
}

var initObjectDetailSlider = function() {
    if ($('#details-slider').length > 0 && $('#object-content').find('[data-tab="Algemeen"]').hasClass('show') ) {
        $slider = $('#details-slider');
        $thumbs = $('#details-thumbs');

        $slider.slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '#details-thumbs'
        });

        $thumbs.slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            asNavFor: '#details-slider',
            initialSlide: 1
        });

        $slider.on('afterChange', function() {
            $thumbs.slick('slickGoTo', ($thumbs.slick('slickCurrentSlide') + 1));
        });

        $('.object-carousel a:not(#leesmeer)').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true
            },
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            }
        });
    }
}

var initObjectKenmerken = function(){
    $('.object-kenmerken').masonry({
      // options
      percentPosition: true,
      itemSelector: '.kenmerk',
      columnWidth: '.kenmerk'
    });
}

var initObjectFotos = function(){
     $('.object-fotos').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Afbeelding #%curr% laden...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Afbeelding #%curr%</a> niet gevonden.',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });
}

var initObjectPlattegronden = function(){
    $('.object-plattegrond').magnificPopup({
        delegate: 'figure a',
        type: 'image',
        tLoading: 'Plattegrond #%curr% laden...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Plattegrond #%curr%</a> niet gevonden.',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });

}

// var initObjectMaps = function() {
//     var geocoder = new google.maps.Geocoder();
//     map = new google.maps.Map(document.getElementById("gmap_locatie"), {
//         zoom: 15,
//         mapTypeId: google.maps.MapTypeId.ROADMAP,
//         scrollwheel: false
//     });

//     if (geocoder) {
//         geocoder.geocode({
//           'address': $('#gmap_locatie').data('address')
//         }, function(results, status) {
//           if (status == google.maps.GeocoderStatus.OK) {
//             if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
//               map.setCenter(results[0].geometry.location);
//               var marker = new google.maps.Marker({
//                     position: results[0].geometry.location,
//                     map: map,
//                     title: $('#gmap_locatie').data('address')
//               }); 
//             } else {
//               alert("No results found");
//             }
//           } else {
//             alert("Geocode was not successful for the following reason: " + status);
//           }
//         });
//     }
// }

function initObjectMaps() {
    var latlng = new google.maps.LatLng($('#gmap_locatie').data('lat'), $('#gmap_locatie').data('lng'));
    
    map = new google.maps.Map(document.getElementById("gmap_locatie"), {
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: latlng,
        scrollwheel: false
    });

    var marker = new google.maps.Marker({
        position: latlng,
        map: map //, icon: '/img/icon-map.png'
    });
}


var initObjectStreetview = function() {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': $('#gmap_streetview').data('address')}, function (results, status) {
        var lookTo = results[0].geometry.location;
        if (status == google.maps.GeocoderStatus.OK) {
            var panoOptions = {
                position: lookTo,
                panControl: false,
                addressControl: false,
                linksControl: false,
                zoomControlOptions: false
            };
            var pano = new google.maps.StreetViewPanorama(document.getElementById('gmap_streetview'), panoOptions);
            var service = new google.maps.StreetViewService;
            service.getPanoramaByLocation(pano.getPosition(), 50, function (panoData) {
                if (panoData != null) {
                    var panoCenter = panoData.location.latLng;
                    var heading = google.maps.geometry.spherical.computeHeading(panoCenter, lookTo);
                    var pov = pano.getPov();
                    pov.heading = heading;
                    pano.setPov(pov);
                } else {
                    $('#gmap_streetview').html('Kan streetview niet genereren');
                }
            });
        } else {
            $('#gmap_streetview').html('Kan streetview niet genereren');
        }
    });
}
